/* Sticky footer styles -------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
#content {
  display: flex;
  flex-direction: column;

  margin-bottom: 2.75rem;
  min-height: calc(100vh - 2.75rem);
  background-color: var(--background);
}
#content > .container {
  flex: 1;
  background-color: var(--midground);

  padding-top: 1em;
  padding-bottom: 1em;
}

@media screen and (max-width: 81rem) {
  .container,
  .container-sm {
   max-width: none;
  }
}

@media screen and (min-width: 87rem) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 83rem;
  }
}

/* Basic page color styling */
/* Not required for printing */
@media screen {
  html {
    background-color: var(--background);
  }
  body {
    color: var(--primaryText);
  }
  #content {
    background-color: var(--background);
  }
  #content > .container {
    background-color: var(--midground);
  }
}

/* Custom page CSS -------------------------------------------------- */
/* Not required for template or sticky footer method. */
code {
  font-size: 80%;
}


a {
  color: var(--primaryMain);
}
a:hover {
  color: var(--primaryLight);
}

h1, .h1, .row > h1, .row > .h1 {
  font-size:     1.6rem;
  margin-top:    0.25em;
  margin-bottom: 0.5em;
}
h2, .h2 {
  font-size:     1.5rem;
  margin-bottom: 0.5em;
}
h3, .h3 {
  font-size:     1.3rem;
  font-weight:   bold;
  margin-bottom: 0.7em;
}
h4, .h4 {
  font-size:     1.15rem;
  font-weight:   bold;
  margin-bottom: 0.5em;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: var(--hoverText);
}
.subtitle {
  font-size: 0.92rem;
  color: var(--secondaryText);
}
.subtitle2 {
  font-size: 0.92rem;
  color: var(--disabledText);
}


.optiwidth {
  max-width: 90ch;
  margin-left: auto;
  margin-right: auto;
}


.accordion.accordion-flush .accordion-item {
  background-color: transparent;
  border-color: var(--inputBorder);
}
.accordion.accordion-flush .accordion-button {
  background-color: var(--elevation);
  color: var(--primaryText);
}
.accordion.accordion-flush .accordion-button:focus {
  box-shadow: 0 0 0 1px var(--primaryText) inset;
}
.accordion.accordion-flush .accordion-button::after {
  filter: hue-rotate(255deg);
}
html[data-theme="dark"] .accordion.accordion-flush .accordion-button.collapsed::after {
  filter: invert(100%);
}


.dropdown-menu {
  color: var(--primaryText);
}
.dropdown-menu.show {
  background-color: var(--elevation);
  max-height: 66vh;
  overflow-y: auto;
}
.dropdown-item,
.dropdown-menu.show > .nav-item > a {
  color: var(--primaryText);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 4px;
}
.dropdown-item:not(.disabled):hover {
  color: var(--hoverText);
  background-color: var(--hoverBg);
}
.dropdown-item:focus {
  color: var(--hoverText);
  background-color: var(--hoverBg);
  box-shadow: 0 0 0 2px var(--primaryText) inset;
  outline: 0;
}
.dropdown-item:active {
  color: var(--hoverText);
  background-color: var(--hoverBg);
  box-shadow: 0 0 0 2px var(--primaryText) inset;
  outline: 0;
}
.dropdown-item.active {
  color: var(--primaryContrast);
  background-color: var(--primaryMain);
  border-radius: 0;
}
.dropdown-item.active:hover {
  color: var(--primaryContrast);
  background-color: var(--primaryLight);
}
.dropdown-menu > .dropstart > .dropdown-toggle::after {
  display: none;
}

.list-group-item {
  color: var(--primaryText);
  background-color: var(--midground);
  padding-top: 0;
  padding-bottom: 0;
  height:      3em;
  line-height: 3em;
}
@media screen and (min-width: 768px), print {
  .list-group-item {
    height:      2.5em;
    line-height: 2.5em;
  }
}
.list-group-item:not(.disabled).active {
  color: var(--primaryText);
  background-color: var(--inputBgDisabled);
  border-color: rgba(0, 0, 0, .125);
}
.list-group-item:not(.disabled).active::after {
  content: "*";
  position: relative;
  display: inline-block;
  width: 0.42em;
  height: 0.42em;
  margin-left: 5px;

  color: transparent;
  font-weight: bold;
}
.list-group-item:not(.disabled):hover {
  color: var(--hoverText);
  background-color: var(--hoverBg);
}
.list-group-item-action:focus, .list-group-item-action:focus-visible {
  color: var(--hoverText);
  background-color: var(--hoverBg);
  box-shadow: 0 0 0 2px var(--primaryText);
  outline: 0;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--disabledText);
  background-color: var(--inputBgDisabled);
}


.card {
  background-color: var(--midground);
  border-color: var(--elevationBorder);
}
.card-header {
  background-color: rgba(128, 128, 128, 0.12);
}
.card-footer {
  background-color: rgba(128, 128, 128, 0.12);
  color: var(--secondaryText);
}


.nav-link {
  color: var(--primaryMain);
}
@media screen and (max-width: 767px) {
  .nav-link {
    padding-top:    0.75em;
    padding-bottom: 0.75em;
    padding-right:  1em;
    padding-left:   1em;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--primaryLight);
}

.nav-tabs {
  border-color: var(--inputBorder);
}
.nav-tabs .nav-link {
  color: var(--secondaryText);
}
.nav-tabs .nav-link.active {
  color: var(--primaryText);
  background-color: var(--inputBgActive);
  border-color: var(--inputBorder);
  border-bottom: 0;
}
.nav-tabs .nav-link:not(.active):hover {
  background-color: var(--hoverBg);
}
.nav-tabs .nav-link.active:hover {
  cursor: default;
}


.table {
  color: var(--primaryText);
  height: 1px; /* HACK: Fix height issues in older browser. */
}
.table > thead.hideme {
  height: 1px;
}
.table > thead.hideme {
  height: 1px;
  border: none;
}
.table > thead.hideme > tr {
  height: 1px;
  border: none !important;
}
.table > thead.hideme > tr > th {
  height: 1px;
  border: none !important;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
  left: -10px;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
  color: var(--hoverText);
}
.table-striped > tbody > tr:nth-of-type(2n+1) > td {
  background-color: var(--hoverBg);
  box-shadow: none;
}
.table-striped > tbody > tr:nth-of-type(2n+1) > td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

}
.table-striped > tbody > tr:nth-of-type(2n+1) > td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
  .d-sm-min {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .visible-xs {
    display: none !important;
  }
  .d-sm-max {
    display: none !important;
  }
}

@media screen {
  html[data-theme="dark"] img {
    opacity: 0.92;
  }
  html[data-theme="dark"] img:hover {
    opacity: 1.0;
  }
}

.visibility-hidden {
  visibility: hidden;
  height: 1px;
  position: absolute;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
